import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate, useSearchParams } from "react-router";

import { useToast, VStack } from "@chakra-ui/react";
import { ResendMfaLink } from "@equidefi/portals/components/ResendMfaLink";
import { BackButton, Input, Text } from "@equidefi/ui";
import { useMFACode, useWorkflowLogin } from "@equidefi/portals/hooks/useAuthentication";

import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import { extractErrorMessage } from "@equidefi/portals";
import { useCookies } from "react-cookie";
import { WorkflowButton } from "@/components/workflow/WorkflowButton";

const WorkflowMFA = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const { offering, analytics } = useWorkflowContext();
  const workflowLogin = useWorkflowLogin(offering?.id);
  const submitMFA = useMFACode();
  const [mfaCode, setMfaCode] = useState(searchParams.get("code"));
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(["bcode"]);
  const fromEmailContent = searchParams.get("email") && searchParams.get("code");

  const location = useLocation();

  useDocumentTitle("Multi-Factor Authentication");

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = location.state?.email || searchParams.get("email");

    setIsLoading(true);
    try {
      const response = await submitMFA.mutateAsync({
        email,
        mfa_code: mfaCode,
        offering_id: location.state?.offering_id, // make sure offering_id is sent only after registration
        bcode: cookies?.bcode,
      });
      analytics.track("registered");

      if (response?.investment?.id) {
        toast({
          status: "success",
          description: "Your investment was created, let's get started!",
        });
        navigate(`/offerings/${offering.slug}/${response.investment.id}/profile`);
        return;
      } else {
        navigate(`/offerings/${offering.slug}/choose`);
      }
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: extractErrorMessage(e, "Incorrect code, please try again"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state && !location.state?.password && !location.state?.email)
      navigate(`/offerings/${offering.slug}/login`);
  }, [location, navigate]);

  return (
    <WorkflowContainer title="Two Factor Authentication">
      <Text>
        Your account is protected with two-factor authentication. We've sent you an email. Please enter the code below.
      </Text>
      <WorkflowForm onSubmit={onSubmit}>
        <VStack>
          <Input
            as={NumberFormat}
            name="mfa_code"
            format="######"
            mask="_"
            placeholder="0 0 0 0 0 0"
            value={mfaCode}
            onValueChange={({ value }) => setMfaCode(value)}
            autoComplete="off"
            h="12"
            textAlign="center"
            mb="3"
          />
        </VStack>
        <WorkflowButton isDisabled={mfaCode?.length !== 6} isLoading={isLoading} onClick={onSubmit} mb={5}>
          Submit Code
        </WorkflowButton>
      </WorkflowForm>
      {!fromEmailContent && <ResendMfaLink alignSelf="center" authFunction={workflowLogin} state={location.state} />}
    </WorkflowContainer>
  );
};

export default WorkflowMFA;
