import {
  Alert,
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React, { useMemo } from "react";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import CopyableText from "../CopyableText";
import SendReminderButton from "./SendReminderButton";
import SigningURL from "./SigningUrl";

import {
  isAdmin,
  isOfferingOwner,
} from "../../../../portals/issuer/src/helpers";
import { useInvestmentSignatures } from "../../hooks/useAgreements";
import { useCurrentUser } from "../../hooks/useUsers";

const SignatureIcon = ({ isChecked = false, ...rest }) => {
  if (isChecked) {
    return <Icon.CheckCircle {...rest} />;
  }

  return <Icon.Circle {...rest} />;
};

export const InvestmentSignatureBox = ({
  investmentId,
  signingParty,
  remindableId = null,
  onRemind = () => {},
  onEdit = () => {},
  onDelete = () => {},
  canEditSigners,
}) => {
  const { data: agreement } = useInvestmentSignatures(investmentId);
  const investmentAgreement = agreement?.investment_agreement;

  const { data: user } = useCurrentUser();
  const canDelete =
    canEditSigners && signingParty?.order && signingParty?.order !== 1;

  const showSigningURL = useMemo(
    () =>
      (isAdmin(user) || isOfferingOwner(user, agreement?.offering?.id)) &&
      !!remindableId &&
      signingParty?.id === remindableId,
    [user, remindableId, agreement?.offering?.id, signingParty?.id],
  );

  const showSendReminderButton = useMemo(
    () =>
      !signingParty.signed &&
      !!remindableId &&
      signingParty?.id === remindableId,
    [signingParty?.signed, remindableId, signingParty?.id],
  );

  return (
    <Alert
      as="li"
      status={signingParty.signed ? "success" : "warning"}
      borderWidth="1px"
      borderRadius="lg"
      px="3"
      py=".5"
      backgroundColor={
        signingParty.signed
          ? transparentize("equidefi.green", 0.2)
          : transparentize("equidefi.yellow", 0.2)
      }
      borderColor={signingParty.signed ? "equidefi.green" : "equidefi.yellow"}
      w="full"
      shadow="sm">
      <Flex gap="2" align="center" flexFlow="row nowrap" w="full">
        <Box flexShrink={0}>
          <SignatureIcon isChecked={signingParty.signed} size="21px" />
        </Box>

        <Stack
          alignItems={{ base: "start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          p="2"
          w="full">
          <Text
            isTruncated
            maxW={{ base: "full", md: "25%" }}
            flexShrink="0"
            mb="0"
            as="span"
            textStyle="h3"
            fontWeight="600">
            {signingParty.name}
          </Text>

          <Stack
            direction={{ base: "column", md: "row" }}
            gap="0"
            wrap="wrap"
            maxW={{ base: "full", md: "50%" }}>
            <CopyableText text={signingParty.email} />
            <CopyableText text={signingParty.phone} />
          </Stack>
          <Spacer />
          <HStack mr="2">
            {showSigningURL && (
              <SigningURL
                investmentAgreementId={investmentAgreement?.id}
                signatureId={signingParty?.signature_id}
              />
            )}
            <SendReminderButton
              investmentId={investmentId}
              isVisible={showSendReminderButton}
            />
          </HStack>
          {canEditSigners && (
            <ButtonGroup>
              <IconButton
                size="1em"
                onClick={() => {
                  onEdit({
                    email: signingParty?.email,
                    first_name: signingParty?.first_name,
                    last_name: signingParty?.last_name,
                    middle_name: signingParty?.middle_name,
                    name: signingParty?.name,
                    order: signingParty?.order,
                    phone: signingParty?.phone,
                    id: signingParty?.id,
                  });
                }}
                variant="none"
                icon={<Icon.Edit size="1em" />}
                cursor="pointer"
              />
              {canDelete && (
                <IconButton
                  size="1em"
                  onClick={() => {
                    onDelete(signingParty?.id);
                  }}
                  variant="none"
                  icon={<Icon.Trash size="1em" />}
                  color="red"
                  cursor="pointer"
                />
              )}
            </ButtonGroup>
          )}
        </Stack>
      </Flex>
    </Alert>
  );
};
