import { useFormik } from "formik";

import { DateField, Heading, Input, Modal, Password, Text } from "@equidefi/ui";
import { entityTypeHelperText, entityTypeSchema } from "../signers";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useToast } from "@chakra-ui/react";
import WorkflowForm from "../WorkflowForm";
import { Alert } from "react-bootstrap";
import { ENTITY_TYPE_OPTIONS } from "@equidefi/shared/constants/profile";
import { WorkflowButton } from "@/components/workflow/WorkflowButton";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import moment from "moment";

export const InvestorTypeModal = ({
  initialValues = {},
  isOpen = false,
  onHide,
  onSubmit,
}) => {
  const toast = useToast();
  const onSave = async (data) => {
    try {
      await onSubmit({
        company: data?.company,
        entity_type: data?.entity_type,
        tax_id: data?.tax_id,
        birthdate: data?.birthdate,
        ein: data?.ein,
      });
      toast({
        status: "success",
        description: "Successfully updated investor type",
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description:
          error.response?.data?.errors?.join(". ") ?? "Something went wrong",
      });
    }
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: entityTypeSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onSave(values);
      resetForm();
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title="Edit investor type"
      isOpen
      onClose={() => {
        resetForm();
        onHide();
      }}>
      <WorkflowForm onSubmit={handleSubmit}>
        {values.entity_type ? (
          <Alert
            status="info"
            flexDirection="column"
            borderRadius="8"
            border="1px solid"
            borderColor="equidefi.blue"
            mb="8"
            alignItems="start"
            padding="4">
            <Heading fontWeight="bold" m="0" textStyle="h3">
              {entityTypeHelperText[values.entity_type].header}
            </Heading>
            <Text m="0">{entityTypeHelperText[values.entity_type].text}</Text>
          </Alert>
        ) : null}

        <RadioGroup
          fieldName="entity_type"
          defaultValue={values.entity_type || "INDIVIDUAL"}
          options={ENTITY_TYPE_OPTIONS}
          errors={touched.entity_type && errors}
          setFieldValue={setFieldValue}
        />
        {values?.entity_type === "ENTITY" && (
          <>
            <Input
              label="Entity Name"
              placeholder="e.g. Acme Corporation"
              isRequired
              error={touched.company && errors.company}
              isInvalid={errors.company && touched.company}
              {...getFieldProps("company")}
            />
            <Password
              label="EIN"
              placeholder="Please enter your EIN without '-'"
              isRequired
              maxLength={9}
              error={touched.ein && errors.ein}
              isInvalid={errors.ein && touched.ein}
              checkboxHintText="Show EIN"
              {...getFieldProps("ein")}
            />
          </>
        )}
        {values?.entity_type === "INDIVIDUAL" && (
          <>
            <Password
              label="SSN/TIN"
              isRequired
              maxLength={9}
              error={touched.tax_id && errors.tax_id}
              isInvalid={errors.tax_id && touched.tax_id}
              checkboxHintText="Show SSN/TIN"
              placeholder="Please enter your SSN/TIN without '-'"
              {...getFieldProps("tax_id")}
            />
            <DateField
              label="Date of Birth"
              name="birthdate"
              dateFormat="yyyy-MM-dd"
              value={values.birthdate}
              onChange={(date) => {
                setFieldValue("birthdate", date);
                touched.birthdate = true;
              }}
              onBlur={() => {
                touched.birthdate = true;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  touched.birthdate = true;
                }
              }}
              error={
                errors.birthdate && touched.birthdate
                  ? errors.birthdate
                  : undefined
              }
              isInvalid={errors.birthdate && touched.birthdate}
              showTimeSelect={false}
              showMonthDropdown={true}
              showYearDropdown={true}
              maxDate={(() => moment().subtract(18, "years").toDate())()}
              isRequired
            />
          </>
        )}
        <WorkflowButton isDisabled={!isValid} onClick={handleSubmit}>
          Save
        </WorkflowButton>
      </WorkflowForm>
    </Modal>
  );
};
