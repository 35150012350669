import { tableAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";

export type TableVariants = "list" | string;
export interface TableProps {
  variant?: TableVariants;
}

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0",
  },
  th: {
    textAlign: "left",
    color: "secondary",
    fontSize: "sm",
    fontWeight: "semibold",
    borderBottom: "1px solid",
    borderColor: "secondary.200",
    position: "relative",
    whiteSpace: "nowrap",
  },
  td: {
    fontSize: "sm",
    borderBottom: "1px solid",
    borderColor: "secondary.100",
    alignContent: "start",
  },
});

export const tableTheme = defineStyleConfig({
  baseStyle,
  sizes: {
    md: definePartsStyle({
      th: {
        py: "3",
        px: "4",
      },
      td: {
        py: "3",
        px: "4",
      },
    }),
  },
  variants: {
    list: definePartsStyle({
      table: {
        borderRadius: "lg",
        overflow: "hidden",
      },
      th: {
        userSelect: "none",
        "& > div": {
          display: "inline-flex",
          alignItems: "center",
          width: "100%",
        },
        _first: {
          pl: "8px",
          fontWeight: "medium",
        },
        _last: {
          pr: "8px",
        },
      },
      td: {
        verticalAlign: "middle",
        alignContent: "center",
        _first: {
          pl: "8px",
          fontWeight: "medium",
        },
        _last: {
          pr: "8px",
        },
      },
    }),
  },
  defaultProps: {
    variant: "list",
  },
});
