import { InvestmentPayment } from "@equidefi/database";
import { capitalizeEveryWord } from "./string";

export const paymentTypeDisplay = (payment: InvestmentPayment) =>
  payment.payment_type === "us_bank_account"
    ? "US Bank Account"
    : payment.payment_type
    ? capitalizeEveryWord(payment.payment_type)
    : "Manual";

export const investmentIsCompleted = (investment) => {
  if (!investment) return false;
  return investment?.status !== "PENDING";
};
