import { Outlet } from "react-router";
import WorkflowLayout from "./WorkflowLayout";
import { WorkflowProvider } from "@/pages/workflow/context/WorkflowContext";

const WorkflowRoot = () => {
  return (
    <WorkflowProvider>
      <WorkflowLayout>
        <Outlet />
      </WorkflowLayout>
    </WorkflowProvider>
  );
};

export default WorkflowRoot;
