import { Alert, AlertIcon } from "@chakra-ui/react";
import { Text } from "@equidefi/ui";

const RegDAlert = () => {
  return (
    <Alert
      status="warning"
      borderRadius="lg"
      border="1px solid"
      borderColor="equidefi.yellow"
      boxShadow="sm"
      mb={4}
    >
      <AlertIcon />
      <Text textStyle="body2" m="0">
        As required by SEC rules and regulations, you must be an accredited
        investor to invest in the Offering and must provide
        <Text as="span" textStyle="body2" fontWeight="bold">
          {" 1) "}
        </Text>
        a W2 or tax returns for 2022 and 2023 showing income individually of
        $200,000 or joint filers $300,000,
        <Text as="span" textStyle="body2" fontWeight="bold">
          {" OR 2) "}
        </Text>
        proof of assets showing a net worth of $1 million net of liabilities,
        <Text as="span" textStyle="body2" fontWeight="bold">
          {" OR 3) "}
        </Text>
        a letter from a CPA firm, attorney or financial advisor. If you do not
        meet these requirements, please do not complete the subscription.
      </Text>
    </Alert>
  );
};

export default RegDAlert;
