var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// constants/index.ts
var constants_exports = {};
__export(constants_exports, {
  Offering: () => offerings_exports
});

// constants/offerings.ts
var offerings_exports = {};
__export(offerings_exports, {
  EXEMPTION_TYPES: () => EXEMPTION_TYPES,
  REGULATIONS: () => REGULATIONS,
  REGULATION_A: () => REGULATION_A,
  REGULATION_A_PLUS: () => REGULATION_A_PLUS,
  REGULATION_CF: () => REGULATION_CF,
  REGULATION_D: () => REGULATION_D,
  REGULATION_S: () => REGULATION_S,
  RULES: () => RULES,
  RULE_504B: () => RULE_504B,
  RULE_506B: () => RULE_506B,
  RULE_506C: () => RULE_506C,
  SECTION_4A5: () => SECTION_4A5,
  SELF_UNDERWRITTEN: () => SELF_UNDERWRITTEN,
  STATUS: () => STATUS,
  StockTypeMap: () => StockTypeMap,
  TIERS: () => TIERS,
  TIER_1: () => TIER_1,
  TIER_2: () => TIER_2
});
var REGULATION_A = "Regulation A";
var REGULATION_A_PLUS = "Regulation A+";
var REGULATION_D = "Regulation D";
var REGULATION_S = "Regulation S";
var REGULATION_CF = "Regulation CF";
var SECTION_4A5 = "Section 4(a)(5)";
var SELF_UNDERWRITTEN = "Self-Underwritten";
var RULE_504B = "Rule 504(b)";
var RULE_506B = "Rule 506(b)";
var RULE_506C = "Rule 506(c)";
var TIER_1 = "Tier 1";
var TIER_2 = "Tier 2";
var EXEMPTION_TYPES = {
  REG_A: "REG_A",
  REG_A_PLUS: "REG_A_PLUS",
  REG_D_504_B: "REG_D_504_B",
  REG_D_506_B: "REG_D_506_B",
  REG_D_506_C: "REG_D_506_C",
  REG_S: "REG_S",
  REGULATION_CF: "REGULATION_CF",
  SECTION_4A5: "SECTION_4A5",
  SELF_UNDERWRITTEN: "SELF_UNDERWRITTEN"
};
var TIERS = {
  TIER_1,
  TIER_2
};
var RULES = {
  RULE_504B,
  RULE_506B,
  RULE_506C
};
var REGULATIONS = {
  REGULATION_A,
  REGULATION_A_PLUS,
  REGULATION_D,
  REGULATION_S,
  REGULATION_CF
};
var STATUS = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  ACTIVE: "Active",
  CLOSED: "Closed",
  TERMINATED: "Terminated"
};
var StockTypeMap = {
  common_stock: "Common Stock",
  convertible_note: "Convertible Notes",
  preferred_stock: "Preferred Stock",
  unit: "Units",
  safe: "SAFE",
  in_lieu_of_cash: "In Lieu of Cash"
};

// helpers/offerings.ts
var offeringIsRegD = (offering) => {
  return [
    EXEMPTION_TYPES.REG_D_506_B,
    EXEMPTION_TYPES.REG_D_506_C,
    EXEMPTION_TYPES.REG_D_504_B
  ].includes(offering?.exemption_type);
};
var offeringIsRegA = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_A;
};
var offeringIsRegAPlus = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_A_PLUS;
};
var offeringIsTypeRegA = (offering) => {
  return offeringIsRegA(offering) || offeringIsRegAPlus(offering);
};
var offeringIsRule506c = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_D_506_C;
};
var offeringIsRule506b = (offering) => {
  return offering?.exemption_type === EXEMPTION_TYPES.REG_D_506_B;
};
var offeringIsActive = (offering) => {
  return offering?.status === STATUS.ACTIVE;
};
var offeringIsTerminated = (offering) => {
  return offering?.status === STATUS.TERMINATED;
};
var offeringIsClosed = (offering) => {
  return offering?.status === STATUS.CLOSED;
};
var offeringIsCompleted = (offering) => {
  return offeringIsClosed(offering) || offeringIsTerminated(offering);
};

// helpers/users.ts
var map = {
  initial: (value) => value ? `${value.substring(0, 1)}.` : null,
  full: (value) => value ?? null,
  none: () => null
};
var userFullName = (user, options = {}) => {
  const middle = map[options?.middle ?? "full"]?.(user?.middle_name);
  return [user?.first_name, middle, user?.last_name].filter(Boolean).filter((s) => typeof s === "string").map((s) => s.trim()).filter(Boolean).join(" ");
};

export { constants_exports as Constants, offeringIsActive, offeringIsClosed, offeringIsCompleted, offeringIsRegA, offeringIsRegAPlus, offeringIsRegD, offeringIsRule506b, offeringIsRule506c, offeringIsTerminated, offeringIsTypeRegA, userFullName };
