import {
  Button,
  ButtonGroup,
  ButtonProps,
  Modal as CModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode, useMemo } from "react";

import { Heading } from "@equidefi/ui";

type TButton = ButtonProps & {
  label: string;
  action?: () => void;
};

export type TModalProps<T extends unknown> = ModalProps & {
  title?: string;
  isOpen: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  buttons?: TButton[];
  children?: ReactNode;
  Header?: ReactNode;
  contentProps?: ModalContentProps;
};

export const Modal = <T extends unknown>({
  title,
  isOpen = false,
  children,
  buttons,
  Header,
  onClose,
  onSubmit = () => {},
  contentProps = {},
  ...rest
}: PropsWithChildren<TModalProps<T>>): React.ReactElement => {
  const Buttons = useMemo(() => {
    if (!buttons || !buttons.length) return null;

    return buttons.map(({ label, action, ...rest }) => (
      <Button
        onClick={action}
        aria-label={label}
        key={label}
        px={6}
        fontWeight="600"
        {...rest}>
        {label}
      </Button>
    ));
  }, [buttons]);

  return (
    <CModal
      isOpen={isOpen}
      onClose={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      motionPreset="slideInBottom"
      isCentered
      {...rest}>
      <ModalOverlay backdropFilter="blur(8px)" />

      <ModalContent
        p={5}
        {...(!rest.size ? { w: "auto" } : {})}
        borderRadius="xl"
        {...contentProps}>
        <form onSubmit={onSubmit} noValidate>
          <ModalHeader p="0">
            {Header || (
              <Heading fontWeight="bold" mb="0">
                {title}
              </Heading>
            )}
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody p="0" py="5">
            {children}
          </ModalBody>

          <ModalFooter p="0" justifyContent="start" w="full">
            <ButtonGroup w="full" gap={3}>
              {Buttons}
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </CModal>
  );
};
