import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router";
import * as Yup from "yup";

import { Input, Password, Text } from "@equidefi/ui";
import { WorkflowContainer } from "../WorkflowContainer";
import { isRegD506COffering } from "../../../helpers";
import WorkflowForm from "../WorkflowForm";
import "./auth.css";
import { useWorkflowContext } from "../context/WorkflowContext";
import { useCookies } from "react-cookie";
import { useWorkflowLogin } from "@equidefi/portals/hooks/useAuthentication";
import RegDAlert from "@/components/investment/RegDAlert";
import { WorkflowButton } from "@/components/workflow/WorkflowButton";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

const userSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email("Must be a valid email")
    .required("Please enter your email address"),
  password: Yup.string().required("Please enter a password"),
});

const initialValues = {
  accepted_tos: null,
  email: "",
  password: "",
};

const Login = () => {
  const location = useLocation();
  const { offering } = useWorkflowContext();
  const workflowLogin = useWorkflowLogin(offering?.id);
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(["bcode"]);
  useDocumentTitle("Log In");

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await workflowLogin.mutateAsync({
        email: data.email,
        password: data.password,
        bcode: `${cookies.bcode}`,
        type: "investor",
      });

      navigate(`/offerings/${offering.slug}/mfa`, { state: data });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description:
          error?.response?.data?.errors.join(". ") ||
          "There was an error trying to login.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { handleSubmit, getFieldProps, errors, isValid, touched, values } =
    useFormik({
      initialValues: location?.state?.initialValues || initialValues,
      enableReinitialize: true,
      validationSchema: userSchema,
      onSubmit,
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    });

  return (
    <>
      <WorkflowContainer title={`Welcome, Investor!`}>
        <Text mb="0">
          Thank you for your interest in {offering.issuer.name}
        </Text>
        <Text>Register or log in to start your investment.</Text>

        <ButtonGroup w="full" variant="solid" mb="4">
          <Button
            w="full"
            as={Link}
            to={"../registration"}
            state={{ initialValues: values }}
            boxShadow="lg"
            colorScheme="gray"
            bgColor="white"
            color="black"
          >
            REGISTER
          </Button>
          <Button
            w="full"
            backgroundColor="#D9DFE5"
            boxShadow="lg"
            border="2px black solid"
            color="black"
            _hover={{}}
            _active={{}}
            cursor="none"
          >
            LOG IN
          </Button>
        </ButtonGroup>
        {isRegD506COffering(offering.exemption_type) && <RegDAlert />}

        <WorkflowForm onSubmit={handleSubmit}>
          <Input
            label="Email"
            error={errors.email}
            isInvalid={errors.email && touched.email}
            isRequired
            {...getFieldProps("email")}
          />
          <Password
            label="Password"
            error={errors.password}
            isInvalid={errors.password && touched.password}
            isRequired
            borderRadius={5}
            {...getFieldProps("password")}
          />
        </WorkflowForm>
        <WorkflowButton
          isDisabled={!isValid}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Log In
        </WorkflowButton>
        <Center mt="4">
          <Text
            as={Link}
            _hover={{ color: "blue.700" }}
            color="blue.500"
            fontSize="md"
            to={`/forgot`}
            state={{
              redirectUrl: `/offerings/${offering.slug}/registration`,
            }}
          >
            Forgot Password?
          </Text>
        </Center>
      </WorkflowContainer>
    </>
  );
};

export default Login;
