import { CardList, CardListItem, Heading, Paper, Text } from "@equidefi/ui";
import { useWorkflowContext } from "../context/WorkflowContext";
import { useContext, useEffect, useMemo, useState } from "react";
import useWorkflowPosition from "@/hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { VaultContext } from "@equidefi/portals/context/VaultProvider";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  HStack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { CurrencyFormatter } from "@equidefi/portals/helpers/format";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import moment from "moment";
import { SignerModal } from "@equidefi/portals/components/SignerModal";
import { AddressModal } from "@/pages/app/vault/profile/AddressModal";
import { useInvestmentSigners } from "@equidefi/portals/hooks/useSigners";
import {
  useSaveVaultAddress,
  useSaveVaultEntity,
} from "@equidefi/portals/hooks/useVault";
import { InvestorTypeModal } from "./InvestorTypeModal";

const ReviewCard = ({ title, editable = false, onEdit, children }) => {
  return (
    <Paper p="4">
      <HStack justifyContent="space-between" mb="2">
        <Heading mb="0" fontWeight="bold">
          {title}
        </Heading>
        {editable ? (
          <Button onClick={onEdit} variant="ghost" gap="2">
            <Icon.Edit size="1.2em" />
            Edit
          </Button>
        ) : (
          <Box as={Icon.Lock} size="1.2em" mr="3" color="secondary" />
        )}
      </HStack>

      {children}
    </Paper>
  );
};

export const WorkflowReview = () => {
  const { investment, refetchInvestment } = useWorkflowContext();
  useWorkflowPosition(6);

  const signerModal = useDisclosure();
  const addressModal = useDisclosure();
  const investorTypeModal = useDisclosure();

  const { vault, refetch: refetchVault } = useContext(VaultContext);

  const navigate = useNavigate();
  const saveVaultAddress = useSaveVaultAddress(investment?.id);

  const onSubmit = () => {
    navigate("../kyc", { state: { reviewed: true } });
  };

  useEffect(() => {
    refetchVault();
    refetchInvestment();
  }, []);

  const {
    signers,
    onEdit,
    onCreate,
    onSubmit: onSubmitSigner,
    canAddMoreSigners,
    initialValues: signer,
  } = useInvestmentSigners(investment?.id, vault?.entity_type, {
    onSuccess: () => {
      signerModal.onClose();
    },
  });

  const entityUpdate = useSaveVaultEntity(investment?.id);

  const primarySigner = useMemo(() => {
    return investment?.signing_parties.find(({ role }) => role === "signer1");
  }, [investment?.signing_parties, investment]);

  return (
    <>
      <WorkflowContainer
        title="Review Your Investment"
        buttonLabel="Continue to Identity Check"
        onContinue={onSubmit}>
        <Text>
          Please make sure these details are correct. You will not be able to
          change them later.
        </Text>
        <CardList mb="4">
          <ReviewCard title="Investment Amount">
            <Text mb="0">{`${CurrencyFormatter.format(investment?.amount)} in ${
              investment?.quantity
            } share${investment?.quantity > 1 ? "s" : ""}`}</Text>
          </ReviewCard>
          <ReviewCard title="Payment Method">
            <Text mb="0">{investment?.payment_type}</Text>
          </ReviewCard>
          <ReviewCard
            title="Investor Type"
            editable
            onEdit={investorTypeModal.onOpen}>
            <Text mb="0">
              {vault?.entity_type
                ? capitalizeEveryWord(vault?.entity_type)
                : ""}
            </Text>
            {vault?.entity_type === "ENTITY" ? (
              <>
                <Text mb="0">Entity Name: {vault?.company}</Text>
                <Text mb="0">EIN: {vault?.ein}</Text>
              </>
            ) : (
              <Text mb="0">Name: {userFullName(vault)}</Text>
            )}
          </ReviewCard>
          <ReviewCard title="Address" editable onEdit={addressModal.onOpen}>
            <Text mb="0">{`${vault?.address_street_1} ${
              vault?.address_street_2 ? `, ${vault?.address_street_2}` : ""
            } `}</Text>
            <Text mb="0">{`${vault?.address_city}, ${vault?.address_state} ${vault?.address_postal_code}`}</Text>
            <Text mb="0">{vault?.address_country_code}</Text>
          </ReviewCard>

          <ReviewCard
            title="Primary Signer"
            editable
            onEdit={() => {
              onEdit(primarySigner);
              signerModal.onOpen();
            }}>
            <Text mb="0" fontWeight="bold">
              {userFullName(primarySigner)}
            </Text>
            <Text mb="0" color="secondary">
              {primarySigner?.phone}
            </Text>
            <Text mb="0" color="secondary">
              SSN: {primarySigner?.ssn}
            </Text>
            <Text mb="0" color="secondary">
              DOB: {moment(primarySigner?.birthdate).format("DD/MM/YYYY")}
            </Text>
          </ReviewCard>

          {signers?.map((signer) => {
            return (
              <ReviewCard
                title="Additional Signer"
                editable
                onEdit={() => {
                  onEdit(signer);
                  signerModal.onOpen();
                }}>
                <Text mb="0" fontWeight="bold">
                  {userFullName(signer)}
                </Text>
                <Text mb="0" color="secondary">
                  {signer?.email}
                </Text>
                <Text mb="0" color="secondary">
                  {signer?.phone}
                </Text>
                <Text mb="0" color="secondary">
                  SSN: {signer?.ssn}
                </Text>
                <Text mb="0" color="secondary">
                  DOB: {moment(signer?.birthdate).format("DD/MM/YYYY")}
                </Text>
              </ReviewCard>
            );
          })}
          {canAddMoreSigners && (
            <CardListItem
              key="add"
              onClick={() => {
                onCreate();
                signerModal.onOpen();
              }}
              action={<IconButton icon={<Icon.Plus size="1.5em" />} />}>
              <Text textStyle="context" my="2">
                Add another signer
              </Text>
            </CardListItem>
          )}
        </CardList>
      </WorkflowContainer>

      <InvestorTypeModal
        isOpen={investorTypeModal.isOpen}
        onHide={investorTypeModal.onClose}
        onSubmit={async (values) => {
          await entityUpdate.mutateAsync(values);
          refetchVault();
        }}
        initialValues={vault}
      />

      <SignerModal
        isOpen={signerModal.isOpen}
        initialValues={signer}
        onHide={signerModal.onClose}
        onSubmit={async (values) => {
          await onSubmitSigner(values);
          refetchInvestment();
        }}
      />
      <AddressModal
        isOpen={addressModal.isOpen}
        onClose={addressModal.onClose}
        onSave={async (values) => {
          await saveVaultAddress.mutateAsync(values);
          refetchVault();
        }}
        initialValues={vault}
      />
    </>
  );
};
