import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";

import {
  DROPZONE_ACCEPTED_TYPES,
  RESTRICTED_CONTENT_TYPES,
} from "@equidefi/shared/constants/files";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import AWSClient from "../../clients/AWSClient";
import { useIsMobile } from "../../hooks/useIsMobile";
import {
  useGetDocumentURLs,
  usePresignedUrl,
  useUpdateVault,
} from "../../hooks/useAccreditation";

export const UploadComponent = ({
  title,
  investmentId,
  vaultFields = [],
  handleChange = () => {},
  noDocumentWarning,
  vault,
}) => {
  const [files, setFiles] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const getPresignedUrl = usePresignedUrl(investmentId);
  const updateVault = useUpdateVault(investmentId);
  const toast = useToast();
  const isMobile = useIsMobile();
  const { data: downloadUrls, isLoading } = useGetDocumentURLs(vault?.id, {
    refetchOnMount: "always",
  });
  const hideDragDrop =
    vault?.status === "APPROVED" || vaultFields.length <= files.length;

  useEffect(() => {
    if (downloadUrls) {
      setFiles(
        vaultFields.reduce((acc, field, idx) => {
          if (!downloadUrls[field]) return acc;

          acc.push({
            name: `File ${idx + 1}`,
            field: field,
            url: downloadUrls[field],
          });

          handleChange({
            target: {
              name: field,
              value: downloadUrls[field],
            },
          });

          return acc;
        }, []),
      );
    }
  }, [downloadUrls, isLoading]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (files.length + acceptedFiles.length > vaultFields.length) {
        toast({
          status: "error",
          description: `You can upload a maximum of ${vaultFields.length} files.`,
        });
        return;
      }

      const newFiles = [];
      setSpinner(true);
      for (const [index, file] of acceptedFiles.entries()) {
        const filename = file.name.split(" ").join("_");
        const extension = filename.split(".").pop().toLowerCase();

        try {
          const vaultField = vaultFields.at(files.length + index);
          const response = await getPresignedUrl.mutateAsync({
            vaultField,
            fileExtension: extension,
          });
          const client = new AWSClient();
          await client.s3Upload(
            response.signedUrl,
            file,
            RESTRICTED_CONTENT_TYPES[extension],
            file.size,
          );
          await updateVault.mutateAsync({
            [response.savedField]: response.url,
          });
          handleChange({
            target: {
              name: response.savedField,
              value: response.url,
            },
          });
          const filePosition = files.length + index + 1;
          newFiles.push({
            name: `File ${filePosition}`,
            field: response.savedField,
            url: response.previewSignedUrl,
          });
          toast({
            status: "success",
            description: "Document uploaded successfully!",
          });
        } catch (e) {
          console.error(e);
          toast({
            status: "error",
            description: "Sorry, the document upload failed.",
          });
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setSpinner(false);
    },
    [files, vaultFields, getPresignedUrl, handleChange],
  );

  const removeFile = useCallback(
    async (field) => {
      try {
        handleChange({
          target: {
            name: field,
            value: null,
          },
        });
        await updateVault.mutateAsync({
          [field]: null,
        });
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.filter((file) => file.field !== field);

          return updatedFiles.map((file, index) => ({
            ...file,
            name: `File ${index + 1}`,
          }));
        });

        toast({
          status: "success",
          description: `The file was removed!`,
        });
      } catch (e) {
        console.error(e);
        toast({
          status: "error",
          description: "Removing the file failed.",
        });
      }
    },
    [updateVault, handleChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: DROPZONE_ACCEPTED_TYPES,
    onDropRejected: (files) => {
      for (const { file } of files) {
        const extension = file.name.split(".").pop().toLowerCase();

        toast({
          status: "error",
          description: `You cannot upload a ${extension} file type, please upload one of these supported types: ${Object.keys(
            RESTRICTED_CONTENT_TYPES,
          ).join(", ")}`,
        });
      }
    },
  });

  return (
    <>
      <Heading textStyle="h2" fontSize="xl" mb={4}>
        {title}
      </Heading>
      <VStack spacing={1} align="stretch">
        {files.length === 0 && noDocumentWarning && (
          <Alert
            status="warning"
            borderRadius="lg"
            border="1px solid"
            borderColor="equidefi.yellow"
            boxShadow="sm"
            my={4}>
            <AlertIcon />
            <AlertDescription>
              <Text fontWeight="bold" m="0" as="h4">
                No Documents Uploaded
              </Text>
              <Text textStyle="body2" m="0">
                {noDocumentWarning}
              </Text>
            </AlertDescription>
          </Alert>
        )}
        {files.map((file, index) => (
          <HStack
            key={index}
            justify="space-between"
            p={1}
            borderBottomWidth={index < files.length - 1 ? "1px" : "0"}>
            <Text noOfLines={2} pt="3" flex={1}>
              {file.name}
            </Text>
            <HStack spacing={0} flex={1} justify="flex-end">
              {vault?.status !== "APPROVED" && (
                <Button
                  mx={isMobile ? 0 : 1}
                  p={isMobile ? 0 : 3}
                  color="red.500"
                  rightIcon={<Icon.Trash2 size="1.4em" />}
                  aria-label="Remove file"
                  variant="ghost"
                  onClick={() => removeFile(file.field)}>
                  {!isMobile && "Delete File"}
                </Button>
              )}
              <Button
                mx={isMobile ? 0 : 1}
                p={isMobile ? 0 : 3}
                color="equidefi.blue"
                rightIcon={<Icon.Download size="1.4em" />}
                aria-label="Link of file"
                variant="ghost"
                onClick={() => window.open(file.url, "_blank")}>
                {!isMobile && "Download File"}
              </Button>
            </HStack>
          </HStack>
        ))}

        {!hideDragDrop && (
          <Box
            {...getRootProps()}
            borderWidth="1px"
            borderStyle="dashed"
            borderRadius="md"
            p={4}
            textAlign="center"
            cursor="pointer">
            <input {...getInputProps()} />
            {spinner ? (
              <Spinner />
            ) : (
              <Text color="gray.500" pt="3" as="span">
                {isMobile ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Text m={2}>Add New File</Text>
                    <Icon.Plus size="1.2em" />
                  </Flex>
                ) : (
                  "Drop Files Here To Upload Them"
                )}
              </Text>
            )}
          </Box>
        )}
      </VStack>
    </>
  );
};
