import { useQuery } from "@tanstack/react-query";

import type { DTO } from "@equidefi/shared/types";
import InquiryClient from "../clients/InquiryClient";
import { useApi } from "./useApi";

export const useInvestmentInquiries = (investmentId, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery<{ data: DTO.TInquiry[] }>(
    ["investment", investmentId, "inquiries"],
    () => inquiryApi.getInvestmentInquiries(investmentId),
    {
      enabled: !!investmentId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    },
  );
};

export const useGetInquiry = (inquiryId: string, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery([inquiryId, "inquiry"], () => inquiryApi.find(inquiryId), {
    enabled: !!inquiryId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
};
