import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { zxcvbn } from "@zxcvbn-ts/core";

import {
  Input,
  isValidPhoneNumber,
  Password,
  PhoneField,
  Text,
} from "@equidefi/ui";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { extractErrorMessage } from "@equidefi/portals";
import { isRegD506COffering } from "../../../helpers";

import { WorkflowButton } from "@/components/workflow/WorkflowButton";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import "./auth.css";
import { useWorkflowContext } from "../context/WorkflowContext";
import RegDAlert from "../../../components/investment/RegDAlert";
import { useWorkflowRegister } from "@equidefi/portals/hooks/useAuthentication";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

const userSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email("Must be a valid email")
    .required("Please enter your email address"),
  first_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "First name must be at least 2 characters")
    .required("Please enter your first name"),
  last_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "Last name must be at least 2 characters")
    .required("Please enter your last name"),

  phone: Yup.string()
    .nullable()
    .required("Please enter your phone number")
    .test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : false),
    }),
  password: Yup.string()
    .required("Please enter a password")
    .test(function (password = "") {
      const passwordCheck = zxcvbn(password);

      return passwordCheck.score <= 1
        ? this.createError({
            message: `Password is too weak. ${passwordCheck.feedback?.suggestions?.join(
              " ",
            )}`,
            path: "password",
          })
        : true;
    }),
});

const initialValues = {
  email: "",
  password: "",
};

const Register = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const { refetch: refetchUser } = useCurrentUser();

  const { offering } = useWorkflowContext();
  const workflowRegister = useWorkflowRegister(offering?.id);
  const [passwordStrength, setPasswordStrength] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  useDocumentTitle("Register");

  const onSubmit = async (data) => {
    setIsLoading(true);
    const time = new Date();

    try {
      const response = await workflowRegister.mutateAsync({
        ...data,
        accept_tos: time,
        accept_privacy: time,
        type: "investor",
      });
      refetchUser();
      dispatch({ type: "email", payload: data.email });
      dispatch({ type: "user", payload: response.data.user });

      navigate(`/offerings/${offering.slug}/mfa`, {
        state: { ...data, offering_id: offering.id },
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: extractErrorMessage(
          error,
          "There was an error trying to register your account.",
        ),
      });
    }

    setIsLoading(false);
  };

  const {
    handleSubmit,
    handleBlur,
    getFieldProps,
    setFieldValue,
    errors,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: location?.state?.initialValues || initialValues,
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <WorkflowContainer title={`Welcome, Investor!`}>
      <Text mb="0">Thank you for your interest in {offering.issuer.name}</Text>
      <Text>Register or log in to start your investment.</Text>

      {isRegD506COffering(offering.exemption_type) && <RegDAlert />}

      <ButtonGroup w="full" variant="solid" mb="4">
        <Button
          w="full"
          backgroundColor="#D9DFE5"
          boxShadow="lg"
          border="2px black solid"
          color="black"
          _hover={{}}
          _active={{}}
          cursor="none">
          REGISTER
        </Button>
        <Button
          w="full"
          as={Link}
          to={"../login"}
          state={{ initialValues: values }}
          boxShadow="lg"
          colorScheme="gray"
          bgColor="white"
          color="black">
          LOG IN
        </Button>
      </ButtonGroup>

      <WorkflowForm onSubmit={handleSubmit}>
        <Input
          label="First Name"
          error={errors.first_name}
          isInvalid={errors.first_name && touched.first_name}
          isRequired
          mb={4}
          {...getFieldProps("first_name")}
        />
        <Input
          label="Last Name"
          error={errors.last_name}
          isInvalid={errors.last_name && touched.last_name}
          isRequired
          mb={4}
          {...getFieldProps("last_name")}
        />
        <Input
          label="Email Address"
          error={errors.email}
          isInvalid={errors.email && touched.email}
          isRequired
          mb={4}
          {...getFieldProps("email")}
        />
        <PhoneField
          label="Phone Number"
          handleBlur={handleBlur}
          isInvalid={errors?.phone && touched.phone}
          error={errors?.phone}
          setFieldValue={setFieldValue}
          isRequired
          mb={4}
          {...getFieldProps("phone")}
          onChange={(value) => {
            setFieldValue("phone", value);
          }}
        />
        <Password
          label="Create a Password"
          error={errors.password}
          isInvalid={errors.password && touched.password}
          isRequired
          borderRadius={5}
          strength={passwordStrength}
          {...getFieldProps("password")}
          onChange={(value) => {
            const test = zxcvbn(value.target.value);
            setPasswordStrength(test.score);
            setFieldValue("password", value.target.value);
          }}
        />

        <Text textStyle="context">
          By creating an account you are agreeing to the{" "}
          <Text
            textStyle="context"
            as={Link}
            _hover={{ color: "blue.700" }}
            color="blue.600"
            to="https://equidefi.com/terms-of-service/"
            target="_blank">
            Terms of Service
          </Text>{" "}
          and{" "}
          <Text
            textStyle="context"
            as={Link}
            _hover={{ color: "blue.700" }}
            color="blue.600"
            to="https://equidefi.com/privacy-policy"
            target="_blank">
            Privacy Policy
          </Text>{" "}
          and consenting to receive email or SMS messages regarding your
          investment and other relevant offers.
        </Text>
        <WorkflowButton
          isDisabled={!isValid}
          isLoading={isLoading}
          onClick={handleSubmit}>
          Create Your Account
        </WorkflowButton>
        <Center mt="4">
          <Text
            as={Link}
            _hover={{ color: "blue.700" }}
            color="equidefi.blue"
            fontSize="md"
            to={`/support`}>
            Contact Support
          </Text>
        </Center>
      </WorkflowForm>
    </WorkflowContainer>
  );
};

export default Register;
