import { anatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const parts = anatomy("investmentSummaryBox").parts(
  "container",
  "whiteCard",
  "iconWrapper",
  "contentWrapper",
  "arrowWrapper",
  "column",
  "columnTitle",
  "columnText",
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    display: "flex",
    borderRadius: "lg",
    p: "1px",
  },
  whiteCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    bg: "white",
    borderRadius: "lg",
    cursor: "pointer",
    transition: "all 0.2s",
    _hover: { shadow: "md" },
  },
  iconWrapper: {
    color: "white",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    w: { base: "34px", md: "45px" },
    fontSize: "24px",
    "& > svg": {
      ml: "auto",
      mr: "auto",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: { base: "column", md: "row" },
    gap: { base: 4, md: 8 },
    px: 6,
    py: 4,
  },
  arrowWrapper: {
    pr: 4,
    alignItems: "center",
    "& > div": {
      alignItems: "center",
      justifyContent: "center",
      color: "blue.400",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    minW: "150px",
  },
  columnTitle: {
    fontSize: "xs",
    color: "secondary",
    fontWeight: 700,
    textTransform: "uppercase",
    mb: 1,
  },
  columnText: {
    fontSize: "sm",
  },
});

const variants = {
  unknown: definePartsStyle({
    container: {
      bg: "gray.400",
    },
  }),
  expedited: definePartsStyle({
    container: {
      bg: "blue.400",
    },
  }),
  ready: definePartsStyle({
    container: {
      bg: "green.400",
    },
  }),
  success: definePartsStyle({
    container: {
      bg: "green.400",
    },
  }),
  pending: definePartsStyle({
    container: {
      bg: "yellow.400",
    },
  }),
  error: definePartsStyle({
    container: {
      bg: "red.400",
    },
  }),
};

export const investmentSummaryBoxTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
});
