import { VStack, useToast, HStack } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import { useCountdown } from "../hooks/useCountdown";

export const ResendMfaLink = ({
  authFunction = {},
  color = "black",
  alignSelf = "start",
  hasCountdown = true,
  state,
}) => {
  const { countdown, isActive, startCountdown } = useCountdown();
  const toast = useToast();

  const onResend = async () => {
    if (isActive) return;

    try {
      await authFunction.mutateAsync({
        email: state.email,
        password: state.password,
      });

      if (hasCountdown) {
        startCountdown();
      }

      toast({
        status: "info",
        description: `New code sent to ${state.email}`,
      });
    } catch (error) {
      toast({
        status: "error",
        description: "There was an error trying to resend 2FA code",
      });
      console.error(error);
    }
  };

  return (
    <VStack alignSelf={alignSelf} color={color}>
      <Heading fontWeight="bold" m="0" alignSelf="start">
        Not receiving a code?
      </Heading>
      <Text>If you have not received a code after one minute, you can request it to be sent again.</Text>
      <HStack spacing={2} alignSelf={alignSelf}>
        <Text
          color={isActive ? "gray.500" : "blue.600"}
          cursor={isActive ? "default" : "pointer"}
          onClick={isActive ? undefined : onResend}
          _hover={{ color: isActive ? "gray.500" : "blue.800" }}
          mb="0">
          Resend Code
        </Text>
        {isActive && (
          <Text color="gray.500" mb="0">
            ({countdown}s)
          </Text>
        )}
      </HStack>
    </VStack>
  );
};
