import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import swal from "sweetalert";

import { MAX_ADDITIONAL_SIGNERS } from "@equidefi/shared/constants/investments";
import type { DTO } from "@equidefi/shared/types";

import InvestmentClient from "../clients/InvestmentClient";
import { extractErrorMessage } from "../helpers/errors";
import { useInvestmentSignatures } from "./useAgreements";
import { useApi } from "./useApi";

export const useUpdateSigner = (investmentId: string, signerId?: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) =>
    investmentClient.updateSigner(investmentId, signerId, data),
  );
};

export const useDeleteSigner = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (signerId: string) =>
    investmentClient.deleteSigner(investmentId, signerId),
  );
};

export const useCreateSigner = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) => {
    return investmentClient.addSigner(investmentId, data);
  });
};

export const useSetupSigners = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async () => {
    return investmentClient.setupSigners(investmentId);
  });
};

export const useInvestmentSigners = (
  investmentId?: string,
  entityType?: DTO.VaultEntityType,
  options: { onSuccess: () => void } = { onSuccess: () => {} },
) => {
  const [initialValues, setInitialValues] = useState<Partial<DTO.SigningParty>>(
    {},
  );
  const toast = useToast();

  const {
    data,
    refetch: refetchSignatures,
    isFetching,
  } = useInvestmentSignatures(investmentId);

  const setupSigners = useSetupSigners(investmentId as string);

  const triggerRefetch = useCallback(async () => {
    const investors =
      data?.signing_parties?.filter((sp) => sp.order >= 1) ?? [];

    if (!isFetching && !setupSigners.isLoading && !investors.length) {
      await setupSigners.mutateAsync();
      refetchSignatures();
    }
  }, [
    data,
    isFetching,
    setupSigners.isLoading,
    setupSigners.mutateAsync,
    refetchSignatures,
  ]);

  useEffect(() => {
    triggerRefetch();
  }, [triggerRefetch]);

  const signers =
    data?.signing_parties?.filter((sp) => ![0, 1, null].includes(sp.order)) ||
    [];

  const canAddMoreSigners = useMemo(
    () => entityType && signers.length < MAX_ADDITIONAL_SIGNERS[entityType],
    [signers.length, entityType],
  );

  const [signerId, setSignerId] = useState<string | null>();

  const addSigner = useCreateSigner(investmentId as string);
  const editSigner = useUpdateSigner(
    investmentId as string,
    signerId as string,
  );
  const deleteSigner = useDeleteSigner(investmentId as string);

  const onSubmit = async (data: DTO.SigningParty) => {
    try {
      if (initialValues?.email) {
        await editSigner.mutateAsync(data);
      } else {
        await addSigner.mutateAsync(data);
      }
      toast({
        status: "success",
        description: "Successfully saved signer",
      });

      options?.onSuccess?.();
      await refetchSignatures();
      setSignerId(undefined);
      setInitialValues({});
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: extractErrorMessage(e),
      });
      return;
    }
  };

  const onDelete = async (signerId: string) => {
    const status = await swal({
      title: "Delete Signer",
      text: "Are you sure you want to delete the signer?",
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      try {
        await deleteSigner.mutateAsync(signerId);
        toast({
          status: "success",
          description: "The signer was deleted successfully.",
        });
      } catch (error) {
        console.error(error);
        toast({
          status: "error",
          description: "Something went wrong deleting the signer",
        });
      }
      refetchSignatures();
    }
  };

  const onDeleteMultipleSigners = async (signerIds: string[]) => {
    try {
      await Promise.all(
        signerIds.map((signerId) => deleteSigner.mutateAsync(signerId)),
      );

      toast({
        status: "warning",
        description:
          "You can only have two signers when investing as an Individual/Joint, additional signers were removed.",
      });
    } catch (error) {
      toast({
        status: "error",
        description: "An error occurred while deleting signers",
      });
    } finally {
      refetchSignatures();
    }
  };

  const onEdit = async (data: DTO.SigningParty) => {
    const filteredData = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      order: data.order,
    };
    setSignerId(data.id);
    setInitialValues(filteredData);
  };

  const onCreate = async () => {
    setSignerId(undefined);
    setInitialValues({});
  };

  const [isDeletingSigners, setIsDeletingSigners] = useState(false);

  const updateForIndividual = useCallback(async () => {
    if (isDeletingSigners) return;

    const maxIndividualSigners = MAX_ADDITIONAL_SIGNERS.INDIVIDUAL;
    if (signers.length <= maxIndividualSigners) return;

    const excessSignerIds = signers
      .slice(maxIndividualSigners)
      .map((signer) => signer?.id)
      .filter(Boolean);

    if (excessSignerIds.length > 0) {
      try {
        setIsDeletingSigners(true);
        await onDeleteMultipleSigners(excessSignerIds);
      } catch (error) {
        console.error("Failed to delete excess signers:", error);
      } finally {
        setIsDeletingSigners(false);
      }
    }
  }, [signers, isDeletingSigners, onDeleteMultipleSigners]);

  useEffect(() => {
    if (entityType !== "INDIVIDUAL") return;

    updateForIndividual();
  }, [entityType]);

  return {
    signers,
    canAddMoreSigners,
    initialValues,
    refetchSignatures,
    onSubmit,
    onDelete,
    onDeleteMultipleSigners,
    onEdit,
    onCreate,
  };
};
