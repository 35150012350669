import {
  FormControl,
  type FormControlProps,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import {
  Select as ReactSelect,
  Options,
  SingleValue,
  MultiValue,
} from "chakra-react-select";
import type { FC } from "react";
import { forwardRef } from "react";
import type {} from "react-select";
import { Text } from "../Text";

export type TOption = {
  label: string;
  value: unknown;
};

export interface SelectProps {
  name: string;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  options?: Options<TOption>;
  defaultValue?: TOption | TOption[];
  controlProps?: FormControlProps;
  isMulti?: boolean;
  placeholder?: string;
  value?: TOption | TOption[];
  onChange: (newValue: SingleValue<TOption>) => void;
}

export interface MultiSelectProps extends Omit<SelectProps, "onChange"> {
  onChange: (newValue: MultiValue<TOption>) => void;
}

export const Select: FC<SelectProps | MultiSelectProps> = forwardRef(
  (
    {
      name,
      label,
      isRequired = false,
      isInvalid = false,
      isDisabled = false,
      error,
      options = [],
      controlProps = { mb: 6 },
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl
        isRequired={isRequired}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        {...controlProps}>
        <FormLabel as={Text}>{label}</FormLabel>
        <ReactSelect
          name={name}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          options={options}
          {...rest}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

Select.displayName = "Select";
