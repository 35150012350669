import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router";
import { zxcvbn } from "@zxcvbn-ts/core";
import * as Yup from "yup";

import { Button, Center, Checkbox, Text, useToast } from "@chakra-ui/react";
import { Heading, Password } from "@equidefi/ui";

import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import {
  useSetNewPassword,
  useVerifyPasswordToken,
} from "@equidefi/portals/hooks/useUsers";

import LoginContainer from "./LoginContainer";

const initialValues = {
  password: "",
  confirmPassword: "",
};

export const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .test(function (password = "") {
      const passwordCheck = zxcvbn(password);

      return passwordCheck.score <= 1
        ? this.createError({
            message: `Password is too weak. ${passwordCheck.feedback?.suggestions?.join(
              " ",
            )}`,
            path: "password",
          })
        : true;
    }),
  confirmPassword: Yup.string()
    .required("Please enter the password again")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const params = useParams();
  const { redirectUrl = "/" } = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast({ status: "info" });
  useDocumentTitle("Reset Password");
  const setNewPassword = useSetNewPassword();
  const [showPassword, setShowPassword] = useState(false);
  const verifyPasswordToken = useVerifyPasswordToken({
    onInvalid: () => {
      toast({
        status: "error",
        description: "Sorry, the reset password URL is no longer valid",
      });
      navigate("/forgot", { replace: true });
    },
  });

  useEffect(() => {
    verifyPasswordToken({
      token: params.token,
      userId: params.userId,
    });
  }, [params.token, params.userId]);

  const onSubmit = async ({ password }) => {
    try {
      await setNewPassword.mutateAsync({
        password,
        token: params.token,
        userId: params.userId,
      });

      toast({
        description: "Your password has been updated, please login to continue",
      });
      navigate(redirectUrl);
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      toast({
        status: "error",
        description:
          errorMessage ||
          "An error occured while trying to process your request.",
      });
    }
  };

  const { errors, touched, getFieldProps, handleSubmit, isValid } = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <LoginContainer>
      <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Heading color="white" fontWeight="bold" m="0" textStyle="h3">
          Reset Password
        </Heading>
        <Text color="white" mb="5" textStyle="body2">
          Enter a secure password for your account
        </Text>
        <Password
          placeholder="Password"
          error={errors.password}
          isInvalid={errors.password && touched.password}
          h="12"
          mb="5"
          showPasswordOverwrite={showPassword}
          {...getFieldProps("password")}
        />

        <Password
          placeholder="Confirm Password"
          error={errors.confirmPassword}
          isInvalid={errors.confirmPassword && touched.confirmPassword}
          h="12"
          showPasswordOverwrite={showPassword}
          {...getFieldProps("confirmPassword")}
        />

        <Checkbox
          mb="6"
          color="white"
          checked={showPassword}
          onChange={() => setShowPassword((prev) => !prev)}>
          Show password
        </Checkbox>

        <Button
          isLoading={setNewPassword.isLoading}
          isDisabled={!isValid}
          type="submit"
          h="12"
          w="full">
          Reset Password
        </Button>

        <Center mt="5">
          <Text
            fontSize="sm"
            color="gray.400"
            mb="0"
            as={Link}
            _hover={{ color: "gray.500" }}
            to={redirectUrl}>
            Remember your password? Log in
          </Text>
        </Center>
      </form>
    </LoginContainer>
  );
};

export default ResetPassword;
