import {
  Box,
  Input as CInput,
  InputProps as CInputProps,
  Textarea as CTextarea,
  TextareaProps as CTextareaProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputGroup,
} from "@chakra-ui/react";
import { FC, forwardRef, ReactNode } from "react";

export interface InputProps extends CInputProps {
  name: string;
  value?: any;
  helperText?: string;
  label: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  inputRightElement?: ReactNode;
}

export const Input: FC<InputProps> = forwardRef(
  (
    {
      name,
      value,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      labelProps = {},
      mb = "2",
      inputRightElement = null,
      sx,
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mt="1"
        mb={mb}
        size={rest?.size}
        sx={sx}
      >
        <FormLabel mt="0" p="0" fontSize="md" {...labelProps}>
          {label}
        </FormLabel>
        <InputGroup>
          <CInput
            ref={ref}
            name={name}
            value={value ?? undefined}
            bg="white"
            borderWidth="1px"
            borderRadius="5px"
            pt="10px"
            pb="10px"
            m="0"
            type="text"
            {...rest}
          />
          {inputRightElement}
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export interface ExpandableInputProps extends CTextareaProps {
  name: string;
  value?: any;
  helperText?: string;
  label: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
}
export const ExpandableInput: FC<ExpandableInputProps> = forwardRef(
  (
    {
      name,
      value,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      labelProps = {},
      mb = "2",
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mt="1"
        mb={mb}
        size={rest?.size}
      >
        <FormLabel mt="0" p="0" fontSize="md" {...labelProps}>
          {label}
        </FormLabel>
        <InputGroup>
          <Box
            w="full"
            display="grid"
            __css={{
              "&::after, & > textarea": {
                /* Identical styling required!! */
                borderRadius: "md",
                borderColor: "gray.200",
                borderStyle: "solid",
                borderWidth: "1px",
                padding: "0.5rem",
                font: "inherit",
                gridArea: "1 / 1 / 2 / 2" /* Place on top of each other */,
              },
            }}
            data-replicated-value={value}
            _after={{
              content: `attr(data-replicated-value) " "`,
              whiteSpace: `pre-wrap`,
              visibility: "hidden",
            }}
          >
            <CTextarea
              ref={ref}
              name={name}
              value={value ?? ""}
              resize="none"
              overflow="hidden"
              bg="white"
              borderWidth="1px"
              borderRadius="5px"
              maxW="full"
              m="0"
              p="5px 12px"
              mb="8px"
              {...rest}
            />
          </Box>
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
