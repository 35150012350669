import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  Progress,
} from "@chakra-ui/react";
import { FC, ReactNode, forwardRef, useState } from "react";

export interface PasswordProps extends InputProps {
  name: string;
  value?: any;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  helperText?: ReactNode;
  showPasswordOverwrite?: boolean;
  checkboxHintText?: string;
  strength?: number;
}

export const Password: FC<PasswordProps> = forwardRef(
  (
    {
      name,
      label,
      value,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      helperText,
      checkboxHintText = "Show password",
      mb = 5,
      color,
      showPasswordOverwrite,
      strength,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mb={mb}>
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          <Input
            ref={ref}
            type={showPasswordOverwrite || showPassword ? "text" : "password"}
            name={name}
            value={value ?? undefined}
            {...rest}
          />
        </InputGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <FormErrorMessage>{error}</FormErrorMessage>
        {strength !== undefined && (
          <Progress
            mt="2"
            transition="0.2s"
            w="full"
            colorScheme={strength <= 1 ? "red" : "green"}
            value={(strength / 4) * 100}
          />
        )}
        {showPasswordOverwrite === undefined && (
          <Checkbox
            mt="1"
            color={color}
            checked={showPassword}
            isRequired={false}
            onChange={() => setShowPassword((prev) => !prev)}>
            {checkboxHintText}
          </Checkbox>
        )}
      </FormControl>
    );
  },
);
