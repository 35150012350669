import { useCookies } from "react-cookie";
import React from "react";
import { Text } from "@equidefi/ui";
import { WorkflowContainer } from "../WorkflowContainer";
import { isRegD506COffering } from "@/helpers";
import RegDAlert from "@/components/investment/RegDAlert";
import "./auth.css";
import { useCreateInvestment } from "../../../../../../packages/portals/hooks/useInvestments";
import { useNavigate } from "react-router";

import "./auth.css";

const ExistInvestorRegister = ({ offering }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["bcode"]);
  const createInvestment = useCreateInvestment();

  const onInvest = async () => {
    const response = await createInvestment.mutateAsync({
      offering_id: offering.id,
      bcode: cookies.bcode,
    });
    navigate(`/offerings/${offering.slug}/${response.id}/profile`);
  };

  return (
    <WorkflowContainer
      title="Welcome, Investor!"
      buttonProps={{ isLoading: createInvestment.isLoading }}
      onContinue={onInvest}
      buttonLabel="Start Investment">
      <Text>Thank you for your interest in {offering.issuer.name}</Text>

      {isRegD506COffering(offering.exemption_type) && <RegDAlert />}

      <Text textStyle="body2" mt={8} mb={12} align="center" fontWeight="bold">
        We see you are already logged in, click &quot;Start Investment&quot; to begin.
      </Text>
    </WorkflowContainer>
  );
};

export default ExistInvestorRegister;
